/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuthenticator } from '@aws-amplify/ui-react'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import { useRollbar } from '@rollbar/react'
import dayjs from 'dayjs'
import { omit } from 'lodash'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { createOnsiteRegistration } from '../api/mutations'
import EmptyState from '../components/EmptyState'
import PageTitle from '../components/Page/Title'
import Section from '../components/Section'
import StepParticipant from '../components/StepParticipant'
import { formatDate } from '../constants/formats'
import { CartContext } from '../contexts/CartContext'
import { PageLoaderContext } from '../contexts/PageLoaderContext'
import useEventDetails from '../hooks/useEventDetails'
import { getUserOnsiteEvents } from '../utils/ApiHelpers'
import { doMutation } from '../utils/ApiUtils'

const OngoingPaymentNotice: React.FC = () => (
  <div
    style={{
      textAlign: 'center',
      padding: '19px',
      marginBottom: '30px',
      border: '2px solid red',
      color: 'black',
    }}>
    Please <b>DO NOT refresh</b> the page and wait while we are processing your
    submission.
  </div>
)

const doCreateOnsiteRegistration = async (
  cartItems: any[],
  onsiteAdmin: string,
) => {
  const createOnsiteRegistrationPromise = () =>
    new Promise((resolve, reject) => {
      const participants = cartItems.map((cartItem) => ({
        ...omit(cartItem, [
          'userId',
          'quantity',
          'eventName',
          'categoryName',
          'confirmPhoneNumber',
          'confirmEmergencyContactPhone',
          'confirmEmail',
          'isTandem',
          'tandemAgeRange',
          'eventEndDateTime',
          'isBonus',
          'isTriathlon',
          'relayEvent-SWIM',
          'relayEvent-BIKE',
          'relayEvent-RUN',
          'relayEvent-RUN1',
          'relayEvent-RUN2',
          'teamType',
          'firstParticipants',
          'isOnsiteRegistration',
          'subCategories',
          'hasJersey',
          'eventShirtLabel',
          'eventShirtOptions',
        ]),
        ...('relayEvent' in cartItem && {
          relayEvent: cartItem.relayEvent.join(','),
        }),
        birthdate: formatDate(cartItem.birthdate, 'YYYY-MM-DD'),
        ...('tandemBirthdate' in cartItem && {
          tandemBirthdate: formatDate(cartItem.tandemBirthdate, 'YYYY-MM-DD'),
        }),
        registeredBy: onsiteAdmin,
        createdAt: dayjs().tz('Asia/Manila').format(),
      }))

      const pendingOrderInput = {
        participants,
      }

      doMutation(
        'createOnsiteRegistration',
        createOnsiteRegistration,
        pendingOrderInput,
      )
        .then(() => {
          resolve('success')
        })
        .catch((error) => reject(error))
    })

  return new Promise((resolve, reject) => {
    try {
      createOnsiteRegistrationPromise()
        .then(() => resolve('Success'))
        .catch((e) => reject(e))
    } catch (error) {
      reject(error)
    }
  })
}

const OnsiteRegistration: React.FC = () => {
  const { slug } = useParams()
  const { user } = useAuthenticator((context) => [context.user])
  const { event, apiLoading, apiError } = useEventDetails({
    slug: slug || getUserOnsiteEvents(user?.attributes)?.[0],
  })

  const rollbar = useRollbar()

  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(false)
  const { setLoading: setPageLoading } = useContext(PageLoaderContext)

  const { cartItems, clearCart } = useContext(CartContext)

  const displayError = (message: string) => {
    enqueueSnackbar(message, { variant: 'error' })
  }

  const errorCallback = (error: any) => {
    rollbar.critical(error)
    displayError(
      'Oops! Something went wrong! Tawaga ang kinagwapahang dev ani.. :D',
    )
  }

  const handleSubmit = async () => {
    setLoading(true)
    try {
      await doCreateOnsiteRegistration(cartItems, user.attributes.sub)
      clearCart()
      enqueueSnackbar('Registration saved successfully', { variant: 'success' })
      setLoading(false)
    } catch (e) {
      setLoading(false)
      errorCallback(e)
    }
  }

  useEffect(() => {
    setPageLoading(apiLoading || loading)
  }, [loading, apiLoading, setPageLoading])

  const renderContent = () => {
    if (apiError)
      return (
        <EmptyState header={apiError.title} text={apiError.message}>
          <Link href="/events">
            <Button variant="contained" color="secondary">
              BACK TO EVENTS
            </Button>
          </Link>
        </EmptyState>
      )

    if (!event)
      return (
        <EmptyState
          header="Hmm… We cannot find the event you are looking for"
          text=""
        />
      )

    return (
      <>
        <PageTitle title={`Register to ${event?.name}`} />
        <div style={{ height: 48 }} />
        {loading && <OngoingPaymentNotice />}
        <StepParticipant
          show={true}
          event={event}
          isOnsiteRegistration={true}
          handlePrimaryAction={handleSubmit}
          primaryActionText="Submit"
          handleSecondaryAction={() => {
            if (
              confirm(
                'Are you sure you want to remove all participants in the form?',
              )
            ) {
              clearCart()
            }
          }}
          secondaryActionText="Clear Table"
        />
      </>
    )
  }

  return (
    <Section>
      <div style={{ height: 48 }} />
      {!apiLoading && renderContent()}
    </Section>
  )
}
export default OnsiteRegistration
