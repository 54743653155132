import CircularProgress from '@mui/material/CircularProgress'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import useListParticipants from '../hooks/useListParticipants'

const DownloadParticipants: React.FC = () => {
  const location = useLocation()

  const [categoryId, setCategoryId] = useState('')
  const [isFree, setIsFree] = useState(false)
  const [isFormParticipant, setIsFormParticipant] = useState(false)
  const [startTimestamp, setTimestamp] = useState(null)

  useEffect(() => {
    setCategoryId(new URLSearchParams(location.search).get('categoryId'))
    const isFree = new URLSearchParams(location.search).get('free')
    setIsFree(isFree !== null)
    setTimestamp(new URLSearchParams(location.search).get('startTimestamp'))

    const isFormParticipant = new URLSearchParams(location.search).get('fp')
    setIsFormParticipant(isFormParticipant !== null)
  }, [location])

  const { participants, formParticipantTshirtTotal, apiLoading } =
    useListParticipants({
      categoryId,
      isFree,
      startTimestamp,
      isFormParticipant,
    })

  return (
    <Container>
      {apiLoading && <CircularProgress />}

      {isFormParticipant && formParticipantTshirtTotal && (
        <div>
          {Object.keys(formParticipantTshirtTotal).map((tshirtTotal) => (
            <Typography
              sx={{ display: 'block' }}
              key={`tshirtTotal-${tshirtTotal}`}>
              {tshirtTotal}|{formParticipantTshirtTotal[tshirtTotal]}
            </Typography>
          ))}
          <br />
        </div>
      )}
      <Typography sx={{ display: 'block' }}>
        {!isFormParticipant
          ? `#|createdAt|firstName|lastName|birthdate|nationality|gender|shirtSize|jerseySize|eventShirtSelection|additionalId|firstParticipantInfo|bibName|teamName|subCategory|relayEvent|phoneNumber|address|emergencyContactName|emergencyContactPhone|user email|user phoneNumber|virtualProgress|tandem participantName|tandem participant birthdate|tandem participant shirtSize|registeredBy`
          : `#|createdAt|id|firstName|lastName|status|fee|paymentMode|tshirtCount`}
      </Typography>
      {participants
        .sort((a, b) => {
          if (a.createdAt < b.createdAt) return -1
          if (a.createdAt > b.createdAt) return 1
          return 0
        })
        .map((p, i) => (
          <div key={`line${i}`}>
            {!isFormParticipant ? (
              <Typography key={p.id} sx={{ display: 'block' }}>
                {i + 1}|{p.createdAt || ''}|{p.firstName || ''}|
                {p.lastName || ''}|{p.birthdate || ''}|{p.nationality || ''}|
                {p.gender || ''}|{p.shirtSize || ''}|{p.jerseySize || ''}|
                {p.eventShirtSelection || ''}|{p.additionalId || ''}|
                {p.firstParticipantInfo || ''}|{p.bibName || ''}|
                {(p.teamName || '').replaceAll('|', '-') || ''}|
                {p.subCategory || ''}|{p.relayEvent || ''}|{p.phoneNumber || ''}
                |{p.address || ''}|{p.emergencyContactName || ''}|
                {p.emergencyContactPhone || ''}|{p.email || p.user?.email || ''}
                |{p.user?.phoneNumber || ''}|{p.virtualProgress || ''}|
                {p.tandemName || ''}|{p.tandemBirthdate || ''}|
                {p.tandemShirtSize || ''}|{p.registeredBy || ''}
              </Typography>
            ) : (
              <Typography key={p.id} sx={{ display: 'block' }}>
                {i + 1}|{p.createdAt || ''}|
                {(p.participantId || '').replaceAll('|', '-')}|
                {p.firstName || ''}|{p.lastName || ''}|{p.status || ''}|
                {p.fee || ''}|{p.paymentMode || ''}|{p.tshirtCount}
              </Typography>
            )}
          </div>
        ))}
    </Container>
  )
}
export default DownloadParticipants
