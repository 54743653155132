import React from 'react'

import { WORK_SITE } from '../../../../constants/employees'
import FormSelectInput from '../../elements/FormSelectInput'
import FormTextInput from '../../elements/FormTextInput'

const FormParticipantFormContent = ({ withIdCheck, formDisabled }) => {
  return [
    {
      id: 'form-participant-form-details',
      name: 'User Details',
      inputs: [
        {
          ...(withIdCheck && {
            id: 'participantId',
            size: { sm: 12, md: 6 },
            field: (
              <FormTextInput
                name="participantId"
                label="ID #"
                required
                autoComplete="new-participantId"
                disabled={formDisabled}
              />
            ),
          }),
        },
        {
          ...(!withIdCheck && {
            id: 'firstName',
            size: { sm: 12, md: 6 },
            field: (
              <FormTextInput
                name="firstName"
                label="First Name"
                required
                autoComplete="new-firstName"
                disabled={formDisabled}
              />
            ),
          }),
        },
        {
          ...(!withIdCheck && {
            id: 'lastName',
            size: { sm: 12, md: 6 },
            field: (
              <FormTextInput
                name="lastName"
                label="Last Name"
                required
                autoComplete="new-lastName"
                disabled={formDisabled}
              />
            ),
          }),
        },
        {
          id: 'workSite',
          size: { sm: 12, md: 6 },
          field: (
            <FormSelectInput
              name="workSite"
              label="Work Site"
              autoComplete="new-workSite"
              placeholder="Select Work Site"
              disabled={formDisabled}
              options={WORK_SITE.map((value) => ({
                value,
                text: value,
              }))}
              required
            />
          ),
        },
      ],
    },
  ]
}
export default FormParticipantFormContent
