/* eslint-disable @typescript-eslint/no-explicit-any */
import { uniqBy } from 'lodash'
import { useState, useEffect } from 'react'

import useNextToken from './useNextToken'
import { listCategoryFees } from '../api/queries'
import { DEFAULT_ENTRIES_PER_PAGE } from '../constants/api'
import { getItemFee } from '../utils/ApiHelpers'
import { fetchQuery } from '../utils/ApiUtils'

interface AdminFees {
  CARD?: number
  GCASH?: number
  GRAB_PAY?: number
  PAYMAYA?: number
}

export interface CategoryFeeResponse {
  id: string
  fee: number
  earlyBirdFee?: number
  earlyBirdUntil?: string
  adminFee: number
  adminFees?: AdminFees | string
  event: {
    registrationCutOff?: string
  }
  bonusParticipants?: number
  teamType?: string
}

interface Category {
  id: string
  fee: number
  adminFee: number
  adminFees?: AdminFees | string
  registrationCutOff?: string
  bonusParticipants?: number
  teamType?: string
}

// const fetchQuery = async (query: any, variables: any) => {
//   return new Promise((resolve, reject) => {
//     const result = API.graphql({
//       query,
//       variables,
//       authMode: 'AWS_IAM',
//     }) as Promise<{
//       data
//     }>

//     result
//       .then(({ data: { listCategories } }) => {
//         resolve({
//           items: listCategories?.items,
//           nextToken: listCategories?.nextToken,
//         })
//       })
//       .catch((err) => reject(err))
//   })
// }

const useCategoryFees = ({ categoryIds = null }) => {
  const [categories, setCategories] = useState<Category[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const { setNewToken, startUsingToken, onTokenSuccess } = useNextToken()

  useEffect(() => {
    let didCancel = false
    let allItems = []
    let currentNextToken = null
    const fetchData = async () => {
      if (didCancel) return
      setLoading(true)

      if (currentNextToken) startUsingToken(currentNextToken)

      const filter = {}
      if (categoryIds !== null && categoryIds.length > 0)
        filter['or'] = categoryIds.map((id) => ({ id: { eq: id } }))

      try {
        const { items, nextToken: newNextToken } = (await fetchQuery(
          'listCategories',
          listCategoryFees,
          {
            filter,
            limit: DEFAULT_ENTRIES_PER_PAGE,
            ...(currentNextToken && { nextToken: currentNextToken }),
          },
        )) as unknown as { items: CategoryFeeResponse[]; nextToken }
        // setCategories(items)

        allItems = uniqBy([...allItems, ...items], 'id')
        if (currentNextToken) onTokenSuccess(currentNextToken)

        if (newNextToken) {
          currentNextToken = newNextToken
          setNewToken(newNextToken)
          if (allItems.length < DEFAULT_ENTRIES_PER_PAGE) {
            fetchData()
            return
          }
        }

        const newCategories = []
        allItems.forEach((i) => {
          if ('event' in i && i.event !== null) {
            const { adminFees, fee } = getItemFee(i)

            newCategories[i.id] = {
              id: i.id,
              fee,
              adminFee: adminFees.CARD,
              adminFees,
              registrationCutOff: i.event.registrationCutOff,
              earlyBirdFee: i.earlyBirdFee,
              earlyBirdUntil: i.earlyBirdUntil,
              bonusParticipants: i.bonusParticipants || 0,
              teamType: i.teamType,
            }
          }
        })
        setCategories(newCategories)
        allItems = []
        currentNextToken = null
      } catch (e) {
        console.log(e)
        setError({
          title: 'Fail to load category fees',
          message: '',
        })
      }

      setLoading(false)
    }
    fetchData()

    return () => {
      didCancel = true
    }
  }, [categoryIds])

  return {
    categories,
    apiLoading: loading,
    apiError: error,
  }
}

export default useCategoryFees
