/* eslint-disable import/no-named-as-default-member */
/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useState, useEffect } from 'react'

import {
  FormParticipant,
  Participant as ParticipantApi,
  ParticipantsByCategoryIdQuery,
  FormParticipantsByFormEventIdQuery,
} from '../API'
import {
  formParticipantsByFormEventId,
  participantsByCategoryId,
} from '../api/queries'
import { SHIRT_COLOR } from '../constants/employees'
import { FAMILY_TSHIRT_SIZES } from '../constants/models'
import { fetchQuery } from '../utils/ApiUtils'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Manila')
dayjs.extend(isSameOrBefore)

type Participant = Omit<Exclude<ParticipantApi, null>, '__typename'>

const filteredData = (items, startTimestamp, isFree) => {
  return items?.filter((p) => {
    if (isFree) {
      if (startTimestamp !== null) return p.createdAt >= startTimestamp
      return true
    }

    if (
      p !== null &&
      (p.order?.paymentMode === 'PAYPAL' ||
        p.order?.paymentMode === 'MANUAL' ||
        (['CARD', 'GCASH', 'PAYMAYA', 'GRAB_PAY'].includes(
          p.order?.paymentMode,
        ) &&
          p.order?.status === 'payment.paid'))
    ) {
      if (startTimestamp !== null) return p.createdAt >= startTimestamp

      return true
    }
    return false
  })
}

const processFormParticipantsData = (items, tshirtTotal, categories) => {
  const hasCategories = Object.keys(categories).length > 0

  items.forEach((item, index, theItems) => {
    const newTshirtCount = {}

    Object.keys(FAMILY_TSHIRT_SIZES).forEach((size) => {
      ;(hasCategories ? Object.values(categories) : SHIRT_COLOR).forEach(
        (groupName) => {
          newTshirtCount[`${size}_${groupName}`] = 0
        },
      )
    })

    const parsed = JSON.parse(JSON.parse(item.tshirtCount)).map((cnt: any) => ({
      size: cnt.size,
      quantity: parseInt(cnt.quantity),
      ...(hasCategories && {
        category: categories[cnt.categoryId],
      }),
      ...(!hasCategories && {
        color: cnt.color,
      }),
    }))

    theItems[index].tshirtCount = JSON.stringify(parsed)
    parsed.forEach((cnt) => {
      newTshirtCount[
        `${cnt.size}_${hasCategories ? cnt.category : cnt.color}`
      ] += cnt.quantity
    })
    theItems[index].tshirtCountArr = newTshirtCount

    if (!(item.participantId in tshirtTotal))
      tshirtTotal[item.participantId] = {
        createdAt: item.createdAt,
        tshirtCount: parsed,
      }
    else {
      if (
        dayjs(tshirtTotal[item.participantId].createdAt).isSameOrBefore(
          dayjs(item.createdAt),
        )
      ) {
        tshirtTotal[item.participantId] = {
          createdAt: item.createdAt,
          tshirtCount: parsed,
        }
      }
    }
  })

  return { items, tshirtTotal }
}

const processSummaryTshirtCount = (tshirtTotal, categories) => {
  const hasCategories = Object.keys(categories).length > 0
  const summaryTshirtTotal = {}
  Object.keys(FAMILY_TSHIRT_SIZES).forEach((size) => {
    ;(hasCategories ? Object.values(categories) : SHIRT_COLOR).forEach(
      (groupName) => {
        summaryTshirtTotal[`${size}_${groupName}`] = 0
      },
    )
  })

  Object.values(tshirtTotal).forEach((total: any) => {
    total.tshirtCount.forEach((tcnt) => {
      summaryTshirtTotal[
        `${tcnt.size}_${hasCategories ? tcnt.category : tcnt.color}`
      ] += tcnt.quantity
    })
  })

  return summaryTshirtTotal
}

const useListParticipants = ({
  categoryId = '',
  isFree = false, // ONSITE REGISTRATION
  isFormParticipant = false, // FORM PARTICIPANT
  startTimestamp = '',
}) => {
  const [participants, setParticipants] = useState<
    Participant[] | FormParticipant[]
  >([])
  const [formParticipantTshirtTotal, setFormParticipantTshirtTotal] =
    useState(null)
  const [loading, setLoading] = useState(false)
  // const [error ] = useState(null)
  const error = null

  useEffect(() => {
    let didCancel = false

    const fetchData = async () => {
      if (didCancel) return
      setLoading(true)

      let nextNextToken = ''
      let nextNextShirtTotal = {}
      let hasNext = true
      let data = []

      const formEventCategories = {}
      const usedTokens = []

      if (categoryId.toString().length > 0) {
        const getData = async (currentToken, currentShirtTotal) => {
          try {
            const { items, nextToken: newNextToken } = (await fetchQuery(
              !isFormParticipant
                ? 'participantsByCategoryId'
                : 'formParticipantsByFormEventId',
              !isFormParticipant
                ? participantsByCategoryId
                : formParticipantsByFormEventId,
              {
                ...(!isFormParticipant
                  ? {
                      categoryId,
                    }
                  : {
                      formEventId: categoryId,
                    }),
                ...(currentToken !== null &&
                  currentToken.length > 0 && {
                    nextToken: currentToken,
                  }),
              },
            )) as unknown as {
              items: Participant[] | FormParticipant[]
              nextToken: string
            }

            if (currentToken !== null && currentToken.length > 0)
              usedTokens.push(currentToken)

            let newItems = [],
              shirtTotal = {}
            if (isFormParticipant) {
              ;(
                items[0] as FormParticipant
              ).formEvent?.categories?.items?.forEach((a) => {
                formEventCategories[a.id] = a.name
              })

              const processedData = processFormParticipantsData(
                items,
                currentShirtTotal,
                formEventCategories,
              )
              newItems = processedData.items
              shirtTotal = processedData.tshirtTotal
            } else {
              // filter values
              newItems = filteredData(items, startTimestamp, isFree)
            }

            return {
              items: newItems,
              nextToken: newNextToken,
              nextShirtTotal: shirtTotal,
            }
          } catch (e) {
            console.log(e)

            if ('data' in e) {
              const data:
                | ParticipantsByCategoryIdQuery
                | FormParticipantsByFormEventIdQuery = e.data

              const newItems = filteredData(
                data[
                  !isFormParticipant
                    ? 'participantsByCategoryId'
                    : 'formParticipantsByFormEventIdQuery '
                ]?.items,
                startTimestamp,
                isFree,
              )

              return {
                items: newItems,
                nextToken:
                  data[
                    !isFormParticipant
                      ? 'participantsByCategoryId'
                      : 'formParticipantsByFormEventIdQuery '
                  ].nextToken,
              }
            }
          }
        }

        while (hasNext === true) {
          const { items, nextToken, nextShirtTotal } = await getData(
            nextNextToken,
            nextNextShirtTotal,
          )
          data = [...data, ...items]
          nextNextToken = nextToken
          nextNextShirtTotal = nextShirtTotal
          if (nextToken === null) hasNext = false
        }

        const tshirtData = processSummaryTshirtCount(
          nextNextShirtTotal,
          formEventCategories,
        )
        setFormParticipantTshirtTotal(tshirtData)
        setParticipants(data)
      }

      setLoading(false)
    }

    fetchData()
    return () => {
      didCancel = true
    }
  }, [categoryId, startTimestamp])

  return {
    participants,
    formParticipantTshirtTotal,
    apiLoading: loading,
    apiError: error,
  }
}

export default useListParticipants
