/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from 'yup'

import { WORK_SITE } from '../../../../constants/employees'

const validateParticipantId = (value: any, testContext: any) => {
  if (testContext.parent.withIdCheck)
    if (value !== undefined && value !== null)
      return value && value.toString().trim().length > 0
    else return false
  return true
}
const validateName = (value: any, testContext: any) => {
  if (!testContext.parent.withIdCheck)
    if (value !== undefined && value !== null)
      return value && value.toString().trim().length > 0
    else return false
  return true
}

const FormParticipantFormSchema = Yup.object().shape({
  withIdCheck: Yup.boolean(),
  participantId: Yup.string().test(
    'required-with-id-check',
    'ID # is required',
    validateParticipantId,
  ),
  firstName: Yup.string().test(
    'required-if-no-id-check',
    'First name is required',
    validateName,
  ),
  lastName: Yup.string().test(
    'required-if-no-id-check',
    'Last name is required',
    validateName,
  ),
  workSite: Yup.mixed().required('Work Site is required').oneOf(WORK_SITE),
})
export default FormParticipantFormSchema
