import { AttachPaymentIntentRequest } from '../models/Paymongo/PaymentIntent'
import { CreatePaymentMethodRequest } from '../models/Paymongo/PaymentMethod'

export const generateOptions = (
  payload: AttachPaymentIntentRequest | CreatePaymentMethodRequest,
) => {
  const base64 = btoa(`${process.env.REACT_APP_PAYMONGO_PK}:`)

  return {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `Basic ${base64}`,
    },
    body: JSON.stringify({
      data: {
        attributes: payload,
      },
    }),
  }
}

const cardFee = (amount: number) => {
  const newAmount = amount * 0.035
  return newAmount + 15
}
const cardForeignFee = (amount: number) => amount * 0.01

export const generateAdminFees = (amount: number) => {
  const ccBase = cardFee(amount) + cardForeignFee(amount) + amount
  const newCCBase = cardFee(ccBase) + cardForeignFee(ccBase) + amount

  let ccAdjustment = 30 * 0.01
  if (amount >= 19001) ccAdjustment += 2
  else if (amount >= 11000) ccAdjustment += 1

  return {
    CARD: Math.ceil(newCCBase + ccAdjustment - amount),
    GCASH: Math.ceil(amount * 1.02565 - amount),
    GRAB_PAY: Math.ceil(amount * 1.0225 - amount),
    PAYMAYA: Math.ceil(amount * 1.02041 - amount),
  }
}
