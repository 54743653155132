/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Yup from 'yup'

// import { SHIRT_COLOR, WORK_SITE } from '../../../../constants/employees'
import { WORK_SITE } from '../../../../constants/employees'
import {
  FAMILY_TSHIRT_SIZES,
  ONSITE_PAYMENT_MODES,
} from '../../../../constants/models'

const FormParticipantFormSchema = Yup.object().shape({
  // participantId: Yup.string().required('ID # is required'),
  workSite: Yup.mixed().required('Work Site is required').oneOf(WORK_SITE),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  max: Yup.number(),
  hasCategories: Yup.boolean(),
  tshirtCount: Yup.array()
    .of(
      Yup.object().shape({
        size: Yup.mixed()
          .required('T-Shirt Size is required')
          .oneOf(Object.keys(FAMILY_TSHIRT_SIZES)),
        quantity: Yup.number()
          .required('Quantity is required')
          .min(1, 'Minimum of 1'),
        // color: Yup.mixed()
        //   .required('Shirt Color is required')
        //   .oneOf(SHIRT_COLOR),
        categoryId: Yup.string().test(
          'required-with-hasCategories',
          'Category is required',
          (value: any, testContext: any) => {
            if (
              'hasCategories' in testContext.from[1].value &&
              testContext.from[1].value.hasCategories
            ) {
              if (value !== undefined && value !== null) {
                return value && value.toString().trim().length > 0
              } else return false
            }
            return true
          },
        ),
      }),
    )
    .test({
      name: 'validate-total',
      test: (value: any, testContext: any) => {
        const base = testContext.parent.tshirtCount
          ? testContext.parent.tshirtCount
          : value

        const sum = base
          .filter((a) => a.size && a.quantity)
          .reduce(
            (accumulator: number, currentValue: number) =>
              accumulator + parseFloat(currentValue['quantity']),
            0,
          )

        if (sum > testContext.parent.max)
          return testContext.createError({
            message: `Total quantity must not exceed ${testContext.parent.max}`,
            path: `${testContext.path}.0.quantity`,
          })

        return true
      },
    }),
  paymentMode: Yup.mixed()
    .required('Payment mode is required')
    .oneOf(Object.keys(ONSITE_PAYMENT_MODES)),
  atdCb: Yup.boolean(),
})
export default FormParticipantFormSchema
