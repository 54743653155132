export const DEFAULT_ENTRIES_PER_PAGE = 30

export const FORM_ORDER_OMIT_FIELDS = [
  'quantity',
  'eventName',
  'categoryName',
  'confirmPhoneNumber',
  'confirmEmergencyContactPhone',
  'isTandem',
  'hasJersey',
  'tandemAgeRange',
  'eventEndDateTime',
  'isBonus',
  'isTriathlon',
  'relayEvent-SWIM',
  'relayEvent-BIKE',
  'relayEvent-RUN',
  'relayEvent-RUN1',
  'relayEvent-RUN2',
  'teamType',
  'firstParticipants',
  'isOnsiteRegistration',
  'subCategories',
  'jerseySize',
  'hasJersey',
  'hasEventShirt',
  'eventShirtLabel',
  'eventShirtOptions',
]
